import React, { useRef, useEffect } from 'react';

import Icon from '../Icon';
import LoadingIndicator from '../LoadingIndicator';

export default function ContactFormModal({ handleCancel, message = '' }) {
  const isLoading = message === '';

  const selfRef = useRef(null);

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  return (
    <>
      <div
        className={`modal contact-form-modal ${
          isLoading ? 'is-not-loading-complete' : 'is-loading-complete'
        }`}
      >
        <div
          className="modal-container"
          role="dialog"
          aria-live="assertive"
          tabIndex="0"
          ref={selfRef}
        >
          <div className="loading" aria-label="Loading...">
            <LoadingIndicator />
          </div>
          <div className="modal-content">
            <button
              type="button"
              className="modal-close-button"
              aria-label="Close dialog"
              onClick={() => handleCancel()}
            >
              <Icon icon="times" size="lg" className="icon-color" />
            </button>
            {/* Header */}
            <div className="modal-header-container">
              <div className="modal-title-container" />
            </div>

            {/* Body */}
            <div className="modal-body-container">
              <p>{message}</p>
            </div>

            {/* Footer */}
            <div className="modal-footer" />
          </div>
        </div>
      </div>
      <div className="modal-overlay" />
    </>
  );
}
