import React, { useEffect, useState } from 'react';

import Card from '../Card/Card';
import PortfolioCategoryFilter from '../Filters/PortfolioCategoryFilter';
import SectionContainer from '../SectionContainer';

export default function PortfolioList({ data, activeCategory }) {
  const [sortedPortfolios, setSortedPortfolios] = useState([]);

  const getSortedPortfolios = (portfolios) =>
    portfolios.sort((a, b) => {
      const dateA = a.orderDate ?? a.createdAt;
      const dateB = b.orderDate ?? b.createdAt;
      if (dateA < dateB) return 1;
      if (dateA > dateB) return -1;
      return 0;
    });

  useEffect(() => {
    setSortedPortfolios(() => getSortedPortfolios(data.nodes));
  }, [data.nodes]);

  return (
    <>
      <PortfolioCategoryFilter activeCategory={activeCategory} />
      <SectionContainer backgroundColor="white">
        <div className="portfolio-list-container">
          <div className="portfolio-list-grid">
            {sortedPortfolios.map((post) => (
              <Card key={post.id} post={post} />
            ))}
          </div>
        </div>
      </SectionContainer>
    </>
  );
}
