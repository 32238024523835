import React, { useState, useEffect, useCallback } from 'react';

import Button from './Button/Button';
import ContactFormModal from './Modal/ContactFormModal';
import { getUsersIp } from '../utils/getUsersIp';
import { isBrowser } from '../utils/isBrowser';

const initialState = {
  contactReason: '',
  firstName: '',
  lastName: '',
  email: '',
  projectDescription: '',
  modalOpen: false,
  submissionError: false,
  modalMessage: '',
};

const getHutkFromCookie = () => {
  const hutkKeyValue = document.cookie
    .split('; ')
    .find((s) => s.indexOf('hubspotutk') === 0);
  const hutkTokens = hutkKeyValue ? hutkKeyValue.split('=') : null;
  return hutkTokens && hutkTokens[1] ? hutkTokens[1] : null;
};

const submitFormDataToHubSpot = (
  pageName,
  { contactReason, email, firstName, lastName, projectDescription },
  { onSuccess, onFailure }
) => {
  const xhr = new XMLHttpRequest();

  const requestData = JSON.stringify({
    submittedAt: Date.now(),
    fields: [
      {
        name: 'email',
        value: email,
      },
      {
        name: 'firstname',
        value: firstName,
      },
      {
        name: 'lastname',
        value: lastName,
      },
      {
        name: 'project_description',
        value: projectDescription,
      },
      {
        name: 'contactreason',
        value: contactReason,
      },
    ],
    context: {
      hutk: getHutkFromCookie(),
      pageUri: isBrowser ? window.location.href : '',
      pageName,
      ipAddress: getUsersIp(),
    },
  });

  xhr.open(
    'POST',
    `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_TRACKING_CODE}/${process.env.GATSBY_HUBSPOT_CONTACT_FORM_GUID}`
  );
  xhr.setRequestHeader('Content-Type', 'application/json');

  xhr.onreadystatechange = function () {
    if (xhr.readyState.toString() !== '4') {
      return;
    }

    if (xhr.status.toString() === '200') {
      onSuccess(xhr.responseText);
    } else {
      onFailure(xhr.responseText);
    }
  };

  xhr.send(requestData);
};

export default function ContactForm({ data, pageName }) {
  const [state, setState] = useState(initialState);

  function handleInput(e) {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const isFormValid = () =>
    !!state.contactReason &&
    !!state.firstName &&
    !!state.lastName &&
    !!state.email &&
    !!state.projectDescription;

  const handleSubmit = (e) => {
    e.preventDefault();

    setState({ ...state, modalOpen: true, modalMessage: '' });

    submitFormDataToHubSpot(
      pageName,
      {
        contactReason: e.target.elements.contactReason.value,
        email: e.target.elements.email.value,
        firstName: e.target.elements.firstName.value,
        lastName: e.target.elements.lastName.value,
        projectDescription: e.target.elements.projectDescription.value,
      },
      {
        onFailure: (responseText) => {
          console.error(responseText);

          setState({
            ...state,
            modalOpen: true,
            submissionError: true,
            modalMessage:
              'An error occurred while submitting your request. Please check your internet connection and try again.',
          });
        },
        onSuccess: (responseText) => {
          setState({
            ...state,
            contactReason: '',
            firstName: '',
            lastName: '',
            email: '',
            projectDescription: '',
            modalOpen: true,
            submissionError: false,
            modalMessage:
              'Thank you for reaching out! We will be in touch soon and look forward to speaking with you.',
          });

          if (isBrowser) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'contactForm',
              category: 'Contact Form',
              action: 'complete',
              label: pageName,
            });
          }
        },
      }
    );
  };

  const closeModal = useCallback(() => {
    setState({
      ...state,
      modalOpen: false,
      modalMessage: '',
    });
  }, [state]);

  useEffect(() => {
    const onKeyUp = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [closeModal]);

  return (
    <div className="contact-form">
      <h2 className="title colored-title">{data.title}</h2>
      <form className="form" id="contact-form" onSubmit={handleSubmit}>
        <div className="form-fields-container">
          <div className="input-container-full-width">
            <select
              className="select-input"
              name="contactReason"
              value={state.contactReason}
              onChange={handleInput}
            >
              <option value="" disabled>
                How can we help? (Choose One...)
              </option>
              <option value="Project Inquiry">
                Inquiring about a project idea
              </option>
              <option value="Partnership Inquiry">
                Interested in a partnership
              </option>
              <option value="Job Opportunities">
                Interested in job opportunities
              </option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="input-container-half-width first-name">
            <input
              className="text-input"
              type="text"
              name="firstName"
              value={state.firstName}
              onChange={handleInput}
              placeholder="First Name"
            />
          </div>
          <div className="input-container-half-width last-name">
            <input
              className="text-input"
              type="text"
              name="lastName"
              value={state.lastName}
              onChange={handleInput}
              placeholder="Last Name"
            />
          </div>
          <div className="input-container-full-width">
            <input
              className="text-input"
              type="email"
              name="email"
              value={state.email}
              onChange={handleInput}
              placeholder="Email"
              autoComplete="false"
            />
          </div>
          <div className="input-container-full-width">
            <textarea
              className="text-area"
              placeholder="Tell us about your project"
              name="projectDescription"
              value={state.projectDescription}
              onChange={handleInput}
            />
          </div>
        </div>
        <div className="contact-form-button-margin">
          <Button
            type="submit"
            buttonStyle={data.button.buttonStyle}
            disabled={!isFormValid()}
          >
            {data.button.text}
          </Button>
        </div>
      </form>
      {state.submissionError && (
        <p style={{ color: '#ff0000' }}>
          An error occurred while submitting your request. Please check your
          internet connection and try again
        </p>
      )}
      {state.modalOpen && (
        <ContactFormModal
          handleCancel={() => closeModal()}
          message={state.modalMessage}
        />
      )}
    </div>
  );
}
