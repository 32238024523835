import React from 'react';
import ContentWrapper from '../ContentWrapper';
import SectionContainer from '../SectionContainer';

export default function TitleText({ data }) {
  return (
    <SectionContainer {...data}>
      <ContentWrapper>
        <div className={`title-text-grid ${data.textAlignment}`}>
          <div className="title-text-left-content">
            <h2 className="colored-title">{data.title}</h2>
            {data.subtitle ? <h3>{data.subtitle}</h3> : null}
          </div>
          <div className="title-text-right-content">
            <p>{data.description.description}</p>
          </div>
        </div>
      </ContentWrapper>
    </SectionContainer>
  );
}
