import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '../Button/Button';
import JobPostingModal from '../Modal/JobPostingModal';
import SectionContainer from '../SectionContainer';
import ContentWrapper from '../ContentWrapper';

export default function OpenOpportunities({ data }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedPosting, setSelectedPosting] = useState('');

  const jobListings = useStaticQuery(graphql`
    query {
      postings: allContentfulJobListing {
        nodes {
          id
          title
          type
          location
          link
          fullListing {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);

  function handleCancel() {
    setShowModal(false);
    setSelectedPosting('');

    document.body.classList.remove('is-scrolling-prevented');
  }

  function openModal(posting) {
    setSelectedPosting(posting);
    setShowModal(true);

    // Prevent scrolling of the background content
    document.body.classList.add('is-scrolling-prevented');
  }

  useEffect(() => {
    const onKeyUp = (event) => {
      if (event.key === 'Escape') {
        handleCancel();
      }
    };

    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  return (
    <SectionContainer backgroundColor="white">
      <ContentWrapper>
        <div className="open-opportunities-container">
          <h2 className="colored-title">{data.title}</h2>
          {jobListings.postings.nodes.length ? (
            <>
              <p>{data.subtitle}</p>
              <table>
                <tbody>
                  {jobListings.postings.nodes.map((posting) => (
                    <tr key={posting.id}>
                      <td>
                        <div>
                          <h3>{posting.title}</h3>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p>{posting.type}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p>{posting.location}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Button
                            buttonStyle="tertiary"
                            onClick={() => openModal(posting)}
                          >
                            View
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {data.footerText ? <p>{data.footerText}</p> : null}
            </>
          ) : (
            <p>{data.noJobPostingsText}</p>
          )}
          {data.footerEmail ? (
            <a href={`mailto:${data.footerEmail}`}>{data.footerEmail}</a>
          ) : null}
        </div>
        {showModal && (
          <JobPostingModal
            handleCancel={handleCancel}
            posting={selectedPosting}
          />
        )}
      </ContentWrapper>
    </SectionContainer>
  );
}
