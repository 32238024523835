import React from 'react';

import * as color from '../../styles/config/_variables.module.scss';
import SectionContainer from '../SectionContainer';
import BigBulletList from '../BigBulletList';
import EmbeddedMedia from '../EmbeddedMedia';

export default function TitleListWithMedia({ data }) {
  if (!data.image && !data.embeddedCodeSnippet) {
    return null;
  }
  return (
    <SectionContainer {...data}>
      <div className="title-list-with-media-grid">
        <div className="title-list-with-media-left-content">
          <EmbeddedMedia data={data} fit="cover" />
        </div>
        <div
          className={`
          title-list-with-media-right-content 
          ${
            data.displayWhiteBox && !data.embeddedCodeSnippet ? 'white-box' : ''
          } 
          ${data.reverseLayout ? 'reverse-layout' : ''}
          `}
        >
          {data.coloredTitle ? (
            <h2 style={{ color: color[`${data.coloredTitle.color}`] }}>
              {data.coloredTitle.string}
            </h2>
          ) : null}
          <BigBulletList items={data.list} bulletColor="light-blue" />
        </div>
      </div>
    </SectionContainer>
  );
}
