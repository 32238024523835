import React from 'react';
import BigBulletList from '../BigBulletList';
import ContentWrapper from '../ContentWrapper';
import SectionContainer from '../SectionContainer';

export default function TitleList({ data }) {
  return (
    <SectionContainer backgroundColor={data.backgroundColor || 'grey'}>
      <ContentWrapper>
        <div className="title-list-grid">
          <div>
            <h2 className="colored-title">{data.title}</h2>
            {data.description ? <p>{data.description.description}</p> : null}
          </div>
          <BigBulletList items={data.list} bulletColor="light-blue" />
        </div>
      </ContentWrapper>
    </SectionContainer>
  );
}
