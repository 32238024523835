import React from 'react';

// import './change-the-world.css';

import HeroPane from './hero-pane';

import poster from '../ProblemSolving.jpg';
import video2 from '../WebsiteAnimationsCompressed/ProblemSolving.mp4';

const ProblemSolving = ({
  onExitedPane,
  shouldDisplay,
  cx,
  cy,
  startCircleRadius,
  endCircleRadius,
}) => (
  <HeroPane
    id="problem-solving"
    poster={poster}
    video={video2}
    shouldDisplay={shouldDisplay}
    onExitedPane={onExitedPane}
    cx={cx}
    cy={cy}
    startCircleRadius={startCircleRadius}
    endCircleRadius={endCircleRadius}
  >
    <h2>Solving Real-World Problems:</h2>
    <p>
      Critical thinking and problem solving are at the heart of what we do.
      We'll help you address your toughest business challenges with custom
      software solutions.
    </p>
    <a href="https://www.crosscomm.com/portfolio/focusstem-nextgen">
      Explore Case Study
    </a>
  </HeroPane>
);

export default ProblemSolving;
