import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import HeroHome from '../components/Hero/HeroHome';
import HeroGeneric from '../components/Hero/HeroGeneric';
import FeaturedItemsHeroCarousel from '../components/Hero/FeaturedItemsHeroCarousel';
import HeroLanding from '../components/Hero/HeroLanding';
import BlogList from '../components/Section/BlogList';
import ContactFormSection from '../components/Section/ContactFormSection';
import DownloadFile from '../components/Section/DownloadFile';
import FeaturedClients from '../components/Section/FeaturedClients';
import FeaturedPostCarousel from '../components/Section/FeaturedPostCarousel';
import FeaturedPostGrid from '../components/Section/FeaturedPostGrid';
import FeaturedServices from '../components/Section/FeaturedServices';
import IconList from '../components/Section/IconList';
import LinkedImageSection from '../components/Section/LinkedImageSection';
import MediaSection from '../components/Section/MediaSection';
import OpenOpportunities from '../components/Section/OpenOpportunities';
import PortfolioList from '../components/Section/PortfolioList';
import QuoteSection from '../components/Section/QuoteSection';
import StepList from '../components/Section/StepList';
import TitleList from '../components/Section/TitleList';
import TitleListWithMedia from '../components/Section/TitleListWithMedia';
import TitleText from '../components/Section/TitleText';
import TitleTextWithMedia from '../components/Section/TitleTextWithMedia';
import TitleTextWithMediaTwo from '../components/Section/TitleTextWithMediaTwo';
import ServicesList from '../components/Section/ServicesList';
import LayoutTemplate from './LayoutTemplate';
import GetResponseHTMLFormSection from '../components/Section/GetResponseHTMLFormSection';

const renderSwitch = (section, pageContext, blogs, portfolios, pageName) => {
  if (section.sys)
    switch (section.sys.contentType.sys.id) {
      case 'blogList':
        return (
          <BlogList
            key={section.id}
            data={blogs}
            activeCategory={pageContext.blogCategory}
          />
        );
      case 'contactForm':
        return (
          <ContactFormSection
            key={section.id}
            data={section}
            pageName={pageName}
          />
        );
      case 'downloadFile':
        return <DownloadFile key={section.id} data={section} />;
      case 'featuredClients':
        return <FeaturedClients key={section.id} data={section} />;
      case 'featuredPostCarousel':
        return <FeaturedPostCarousel key={section.id} data={section} />;
      case 'featuredPostGrid':
        return <FeaturedPostGrid key={section.id} data={section} />;
      case 'featuredServices':
        return <FeaturedServices key={section.id} data={section} />;
      case 'getResponseHtmlForm':
        return <GetResponseHTMLFormSection key={section.id} data={section} />;
      case 'iconList':
        return <IconList key={section.id} data={section} />;
      case 'linkedImageSection':
        return <LinkedImageSection key={section.id} data={section} />;
      case 'mediaSection':
        return <MediaSection key={section.id} data={section} />;
      case 'openOpportunities':
        return <OpenOpportunities key={section.id} data={section} />;
      case 'portfolioList':
        return (
          <PortfolioList
            key={section.id}
            data={portfolios}
            activeCategory={pageContext.portfolioCategory}
          />
        );
      case 'quoteSection':
        return <QuoteSection key={section.id} data={section} />;
      case 'servicesList':
        return <ServicesList key={section.id} />;
      case 'stepList':
        return <StepList key={section.id} data={section} />;
      case 'titleList':
        return <TitleList key={section.id} data={section} />;
      case 'titleListWithMedia':
        return <TitleListWithMedia key={section.id} data={section} />;
      case 'titleText':
        return <TitleText key={section.id} data={section} />;
      case 'titleTextWithMedia':
        return <TitleTextWithMedia key={section.id} data={section} />;
      case 'titleTextWithMediaTwo':
        return <TitleTextWithMediaTwo key={section.id} data={section} />;
      default:
        return null;
    }
  return null;
};

export default function PageTemplate({ data, pageContext, location }) {
  return (
    <LayoutTemplate
      showNav={data.page.showNavigation}
      footerStyle={data.page.footerStyleRegularVsCondensed}
    >
      <div>
        <Seo
          title={data.page.name}
          location={location}
          pathname={location.pathname}
          image={`https:${data.page.heroSection?.cardImage?.file.url}`}
          description={data.page.seoDescription}
        />
        {data.page.heroSection?.sys.contentType.sys.id === 'heroHomeSection' ? (
          <HeroHome hero={data.page.heroSection} />
        ) : null}
        {data.page.heroSection?.sys.contentType.sys.id ===
        'heroGenericSection' ? (
          <HeroGeneric data={data.page.heroSection} />
        ) : null}
        {data.page.heroSection?.sys.contentType.sys.id ===
        'herocarouselSection' ? (
          <FeaturedItemsHeroCarousel
            items={data.page.heroSection?.featuredItems || []}
          />
        ) : null}
        {data.page.heroSection?.sys.contentType.sys.id ===
        'heroLandingSection' ? (
          <HeroLanding hero={data.page.heroSection} pageName={data.page.name} />
        ) : null}
        {data.page.sections?.map((section) =>
          renderSwitch(
            section,
            pageContext,
            data.blogs,
            data.portfolios,
            data.page.name
          )
        )}
      </div>
    </LayoutTemplate>
  );
}

export const query = graphql`
  query getPage(
    $slug: String!
    $blogCategory: [String]
    $fetchBlogs: Boolean!
    $portfolioCategory: [String]
    $fetchPortfolios: Boolean!
  ) {
    page: contentfulPages(slug: { eq: $slug }) {
      name
      seoDescription
      heroSection {
        ...heroSection
      }
      sections {
        ...sections
      }
      showNavigation
      footerStyleRegularVsCondensed
    }
    blogs: allContentfulBlogEntry(
      filter: { category: { in: $blogCategory } }
      sort: { order: DESC, fields: publishedDate }
    ) {
      ...ContentfulBlogEntryConnectionFragment @include(if: $fetchBlogs)
    }
    portfolios: allContentfulPortfolioPiece(
      filter: { category: { in: $portfolioCategory } }
    ) {
      ...ContentfulPortfolioPieceConnectionFragment
        @include(if: $fetchPortfolios)
    }
  }

  fragment ContentfulBlogEntryConnectionFragment on ContentfulBlogEntryConnection {
    nodes {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      title: blogTitle
      publishedDate(formatString: "MMM Do YYYY")
      slug
      heroImage {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      cardSummaryText {
        cardSummaryText
      }
      cardImage {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }

  fragment ContentfulPortfolioPieceConnectionFragment on ContentfulPortfolioPieceConnection {
    nodes {
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      title: portfolioTitle
      slug
      cardImage {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      createdAt
      orderDate
    }
  }
`;
