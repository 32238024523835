import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../Button/Button';
import ContentWrapper from '../ContentWrapper';
import SectionContainer from '../SectionContainer';

export default function FeaturedServices({ data }) {
  return (
    <SectionContainer {...data} className="featured-services">
      <ContentWrapper>
        <div className="featured-services">
          <h2 className="colored-title">{data.title}</h2>
          <p>{data.description.description}</p>
        </div>
      </ContentWrapper>
      <div className="featured-services-grid">
        {data.services.map((service) => (
          <div key={service.id} className="featured-services-grid-content">
            <Link to={`/services/${service.slug}/`}>
              <GatsbyImage
                style={{
                  gridArea: '1/1',
                }}
                alt={
                  service.heroSection.cardImage.description ||
                  service.heroSection.cardImage.title ||
                  ''
                }
                image={service.heroSection.cardImage.gatsbyImageData}
              />
              <div className="featured-services-grid-overlay-container">
                <div className="featured-services-grid-overlay-content">
                  <h3 className="featured-services-grid-overlay-title">
                    {service.name}
                  </h3>
                  <div className="button-container">
                    <Button buttonStyle="secondary">View</Button>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
}
