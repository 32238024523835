import React from 'react';

import Button from '../Button/Button';
import CustomLink from '../../utils/CustomLink';
import SectionContainer from '../SectionContainer';
import ContentWrapper from '../ContentWrapper';
import EmbeddedMedia from '../EmbeddedMedia';

export default function TitleTextWithMedia({ data }) {
  if (!data.image && !data.embeddedCodeSnippet) {
    return null;
  }
  return (
    <SectionContainer {...data}>
      <ContentWrapper>
        <div
          className={`title-text-with-media-grid ${
            data.reverseLayout ? 'reverse-layout' : ''
          }`}
        >
          <div className="title-text-with-media-right-content">
            <EmbeddedMedia data={data} />
          </div>
          <div className="title-text-with-media-left-content">
            <h2 className="colored-title">{data.title}</h2>
            {data.subtitle ? <h3>{data.subtitle}</h3> : null}
            <p>{data.description.description}</p>
            <CustomLink to={data.button.url}>
              <Button buttonStyle={data.button.buttonStyle}>
                {data.button.text}
              </Button>
            </CustomLink>
          </div>
        </div>
      </ContentWrapper>
    </SectionContainer>
  );
}
