import React from 'react';
import SectionContainer from '../SectionContainer';
import EmbeddedMedia from '../EmbeddedMedia';

export default function TitleTextWithMediaTwo({ data }) {
  if (!data.image && !data.embeddedCodeSnippet) {
    return null;
  }
  return (
    <SectionContainer
      backgroundColor="white"
      className={`title-text-with-media-two${
        data.embeddedCodeSnippet ? ' video' : ''
      }`}
    >
      <div className="title-text-with-media-two-grid">
        <div className="title-text-with-media-two-left-content">
          <EmbeddedMedia data={data} fit="cover" />
        </div>
        <div
          className={`title-text-with-media-two-right-content ${
            data.reverseLayout ? 'reverse-layout' : ''
          }`}
        >
          <h2>{data.title}</h2>
          <p>{data.description.description}</p>
        </div>
      </div>
    </SectionContainer>
  );
}
