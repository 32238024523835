import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Icon from '../Icon';
import Button from '../Button/Button';
import SectionContainer from '../SectionContainer';
import ContentWrapper from '../ContentWrapper';

export default function FeaturedClients({ data }) {
  return (
    <SectionContainer backgroundColor="white">
      <ContentWrapper>
        <div className="featured-clients-container">
          <h2 className="colored-title">{data.title}</h2>
          <p>{data.description.description}</p>
        </div>
      </ContentWrapper>
      <div className="featured-clients-grid">
        {data.clientLinkSection.map((client) => (
          <FeaturedClientsGridItem key={client.id} client={client} />
        ))}
      </div>
    </SectionContainer>
  );
}

const FeaturedClientsGridItem = ({ client }) => {
  if (client.featuredUrl) {
    return (
      <a
        href={client.featuredUrl || ''}
        rel="noopener noreferrer"
        target={client.openInNewTab ? '_blank' : ''}
        className={`featured-clients-grid-item ${
          client.featuredUrl ? 'highlight' : 'highlight'
        }`}
      >
        <FeaturedClientImage client={client} />
      </a>
    );
  }

  return (
    <div
      className={`featured-clients-grid-item ${
        client.featuredUrl ? 'highlight' : ''
      }`}
    >
      <FeaturedClientImage client={client} />
    </div>
  );
};

const FeaturedClientImage = ({ client }) => (
  <>
    <GatsbyImage
      alt={client.name}
      image={client.image.gatsbyImageData}
      objectFit="contain"
    />
    {client.featuredUrl ? (
      <>
        <Icon icon="circle" className="icon-color" />
        <div className="featured-clients-button">
          <Button buttonStyle="secondary">View Case Study</Button>
        </div>
      </>
    ) : null}
  </>
);
