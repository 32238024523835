import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import SectionContainer from '../SectionContainer';

function countBlogsInCategories(blogs) {
  // return blogs
  const counts = blogs
    .map((blog) => blog.category)
    .flat()
    .reduce((acc, category) => {
      const existingCategory = acc[category];
      if (existingCategory) {
        existingCategory.count += 1;
      } else {
        acc[category] = {
          name: category,
          count: 1,
        };
      }
      return acc;
    }, {});
  const sortedCategories = Object.values(counts).sort(
    (a, b) => b.count - a.count
  );
  return sortedCategories;
}

export default function BlogCategoryFilter() {
  const { blogs } = useStaticQuery(graphql`
    query {
      blogs: allContentfulBlogEntry {
        nodes {
          blogTitle
          category
        }
      }
    }
  `);
  const categoriesWithCounts = countBlogsInCategories(blogs.nodes);
  return (
    <SectionContainer backgroundColor="gray">
      <div className="filter-container">
        <div className="filter-button-container">
          <Link to="/resources/blog/" activeClassName="active">
            <button type="button">All</button>
          </Link>
        </div>
        {categoriesWithCounts.map((category) => (
          <div key={category.name} className="filter-button-container">
            <Link
              to={`/resources/blog/category/${category.name}`}
              activeClassName="active"
            >
              <button type="button">{category.name}</button>
            </Link>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
}
