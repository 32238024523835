/**
 * This is a minimal DOM implementation, used for basic DOM manipulation
 * in a SSR environment. Primarily this is used for the GetResponseHTMLForm.
 * It implements a bare-minimum of DOM features needed and is in no way complete, nor
 * is it intended to be so.
 *
 * Key difference in behavior between this and real DOM nodes:
 * - Text nodes are implemented by specifying a tagName of "text". In the actual DOM
 *   this would be a different type of node.
 * - You must use setAttribute/getAttribute to set attrs. For example, you can't do
 *   myNode.value = 100.
 * - tagName is lowercase
 * - Only text nodes and elements are implemented (no comment nodes or others)
 * - When a child node is appended to a node the child is not removed from its previous
 *   parent. Instead you must remove the child before appending it elsewhere if you wish
 *   to maintain this behavior.
 * - This uses `html-dom-parser` to read HTML strings. It's assumed to be correct but of
 *   course that's not a guarantee!
 * - There may be other inconsistences, as this is a DOM approximation. You'll want to
 *   test your use cases if using this.
 */

import parse from 'html-dom-parser';
import FakeClassList from './fake-class-list';

export default class FakeDOMNode {
  constructor(tagName, text = null) {
    this.classList = new FakeClassList();
    this._children = [];
    this.tagName = tagName;
    this.text = text;
    this.attributes = new Map();
    this.parent = null;
    this.nodeType = tagName === 'text' ? 'text' : 'element';
  }

  cloneNode() {
    const clone = new FakeDOMNode('div');
    clone.innerHTML = this.outerHTML;

    return clone.children[0];
  }

  appendChild(child) {
    if (!child) {
      return;
    }

    this.children.push(child);
    child.parent = this;
  }

  setAttribute(attrName, attrVal) {
    this.attributes.set(attrName, attrVal);
  }

  getAttribute(attrName) {
    return this.attributes.get(attrName);
  }

  removeChild(childNode) {
    for (let i = 0, len = this.children.length; i < len; i += 1) {
      if (this.children[i] === childNode) {
        this.children.splice(i, 1);
        break;
      }
    }
  }

  remove() {
    if (!this.parent) {
      return;
    }

    this.parent.removeChild(this);
  }

  set children(newChildren) {
    this._children = [];
    newChildren.forEach((child) => this.appendChild(child));
  }

  get children() {
    return this._children;
  }

  get outerHTML() {
    if (this.tagName === 'text') {
      return this.text;
    }

    const attrs = [...this.attributes]
      .map(([attrName, attrValue]) => `${attrName}="${attrValue}"`)
      .join(' ');

    const className = this.classList.toString();

    return `<${this.tagName}${className ? ` class="${className}"` : ''}${
      attrs ? ` ${attrs}` : ''
    }>${this.children
      .map((child) => (child ? child.outerHTML : ''))
      .join('')}</${this.tagName}>`;
  }

  get innerHTML() {
    return this.children.map((childNode) => childNode.outerHTML).join('');
  }

  set innerHTML(html) {
    const tree = parse(html);

    this.children =
      tree.map((domParserNode) =>
        FakeDOMNode.createFromDOMParserNode(domParserNode)
      ) || [];
  }

  set textContent(newTextContent) {
    if (this.tagName === 'text') {
      this.text = newTextContent;
    } else {
      this.children = [new FakeDOMNode('text', newTextContent)];
    }
  }

  get textContent() {
    if (this.tagName === 'text') {
      return this.text;
    }

    return this.children.map((childNode) => childNode.textContent).join('');
  }

  // Replacement for querySelectorAll
  find(fn) {
    const found = [];

    if (fn(this)) {
      found.push(this);
    }

    return found.concat(this.children.map((child) => child.find(fn))).flat();
  }

  static createFromDOMParserNode(domParserNode) {
    let fakeDOMNode;

    switch (domParserNode.type) {
      case 'text':
        fakeDOMNode = new FakeDOMNode('text', domParserNode.data);
        break;

      case 'tag':
        fakeDOMNode = new FakeDOMNode(domParserNode.name);
        Object.keys(domParserNode.attribs).forEach((attrName) => {
          fakeDOMNode.setAttribute(attrName, domParserNode.attribs[attrName]);
        });
        break;

      default:
        break;
    }

    if (domParserNode.children) {
      fakeDOMNode.children = domParserNode.children.map((domParserChildNode) =>
        FakeDOMNode.createFromDOMParserNode(domParserChildNode)
      );
    }

    return fakeDOMNode;
  }
}
