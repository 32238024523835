import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../Button/Button';
import ContentWrapper from '../ContentWrapper';
import SectionContainer from '../SectionContainer';

export default function ServicesList() {
  const servicesList = useStaticQuery(graphql`
    query {
      services: contentfulNavigationDropdown(title: { eq: "Services" }) {
        pages {
          ... on ContentfulPages {
            name
            slug
            parentName {
              slug
            }
            heroSection {
              ...heroSection
            }
          }
        }
      }
    }
  `);

  return (
    <SectionContainer backgroundColor="white">
      <ContentWrapper>
        <div className="services-list-grid">
          {servicesList.services.pages
            .filter((service) => service.slug)
            .map((service) => (
              <Link
                to={`/services/${service.slug}/`}
                key={service.heroSection.id}
                className="services-list-grid-content"
              >
                <GatsbyImage
                  style={{
                    gridArea: '1/1',
                    height: 405,
                  }}
                  alt={
                    service.heroSection.cardImage.description ||
                    service.heroSection.cardImage.title ||
                    ''
                  }
                  image={service.heroSection.cardImage.gatsbyImageData}
                />
                <div className="services-list-grid-overlay-container">
                  <div>
                    <h3 className="services-list-grid-overlay-title">
                      {service.name}
                    </h3>
                    <div className="button-container">
                      <Button buttonStyle="secondary">View</Button>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </ContentWrapper>
    </SectionContainer>
  );
}
