import React, { useEffect, useState, useRef } from 'react';

import './hero-pane.css';

import LazyLoadVideo from './lazy-load-video';
import HeroInfoPane from './hero-info-pane';
import { getIsReducedMotionPreferred } from '../util';

const ANIMATION_DURATION_MS = 1000;

const getZoomInAnimationSteps = (
  startCircleRadius,
  endCircleRadius,
  cx,
  cy
) => [
  {
    clipPath: `circle(${startCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 0,
  },
  {
    clipPath: `circle(${startCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 1,
  },
  {
    clipPath: `circle(${startCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 1,
  },
  {
    clipPath: `circle(${endCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 1,
  },
];

const getZoomOutAnimationSteps = (
  startCircleRadius,
  endCircleRadius,
  cx,
  cy
) => [
  {
    clipPath: `circle(${endCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 1,
  },
  {
    clipPath: `circle(${startCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 1,
  },
  {
    clipPath: `circle(${startCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 1,
  },
  {
    clipPath: `circle(${startCircleRadius}px at ${cx}px ${cy}px)`,
    opacity: 0,
  },
];

const HeroPane = ({
  id,
  poster,
  video,
  onExitedPane,
  shouldDisplay,
  children,
  cx = 0,
  cy = 0,
  startCircleRadius = 0,
  endCircleRadius = 100,
}) => {
  const selfRef = useRef();
  const [isShowing, setIsShowing] = useState(null);

  useEffect(() => {
    const animateZoomIn = (isReducedMotionPreferred) =>
      selfRef.current.animate(
        getZoomInAnimationSteps(startCircleRadius, endCircleRadius, cx, cy),
        {
          duration: isReducedMotionPreferred ? 0 : ANIMATION_DURATION_MS,
          iterations: 1,
          fill: 'forwards',
        }
      );

    const animateZoomOut = (isReducedMotionPreferred) =>
      selfRef.current.animate(
        getZoomOutAnimationSteps(startCircleRadius, endCircleRadius, cx, cy),
        {
          duration: isReducedMotionPreferred ? 0 : ANIMATION_DURATION_MS,
          iterations: 1,
          fill: 'forwards',
        }
      );

    if (!isShowing && shouldDisplay) {
      setIsShowing(true);
      animateZoomIn(getIsReducedMotionPreferred());
    } else if (isShowing && !shouldDisplay) {
      const onAnimationFinish = () => {
        setIsShowing(false);
        onExitedPane();
      };

      const animation = animateZoomOut(getIsReducedMotionPreferred());
      animation.addEventListener('finish', onAnimationFinish);

      return () => {
        animation.removeEventListener('finish', onAnimationFinish);
      };
    }
  }, [
    isShowing,
    shouldDisplay,
    id,
    cx,
    cy,
    startCircleRadius,
    endCircleRadius,
    onExitedPane,
  ]);

  useEffect(() => {
    if (shouldDisplay) {
      selfRef.current.focus();
    }
  }, [shouldDisplay]);

  return (
    <div
      ref={selfRef}
      tabIndex={0}
      aria-live="assertive"
      id={id}
      name={id}
      className={`hero-pane${isShowing ? ' should-display' : ''}${
        isShowing ? ' should-play-video' : ''
      }`}
    >
      <LazyLoadVideo
        poster={poster}
        video={video}
        shouldPlayVideo={isShowing}
      />
      <HeroInfoPane>{children}</HeroInfoPane>
    </div>
  );
};

export default HeroPane;
