export default class FakeClassList {
  constructor() {
    this.classNames = [];
  }

  add(className) {
    this.classNames.push(className);
  }

  toString() {
    return this.classNames.join(' ');
  }
}
