import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import RichText from '../RichText';
import SectionContainer from '../SectionContainer';

export default function StepList({ data }) {
  return (
    <SectionContainer backgroundColor="white" className="step-list">
      {data.steps.map((step) => (
        <div key={step.id} className="step-list-group">
          <div className="step-list-item step-list-content-one">
            <GatsbyImage
              alt={step.title}
              image={step.image.gatsbyImageData}
              objectFit="contain"
            />
          </div>
          <div className="step-list-item step-list-content-two">
            <h2>{step.title}</h2>
            <RichText content={step.content} />
          </div>
        </div>
      ))}
    </SectionContainer>
  );
}
