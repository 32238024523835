import React from 'react';

import PostCard from '../Card/PostCard';
import BlogCategoryFilter from '../Filters/BlogCategoryFilter';
import SectionContainer from '../SectionContainer';

export default function BlogList({
  data,
  activeCategory,
  showCategories = true,
}) {
  return (
    <>
      {showCategories ? (
        <BlogCategoryFilter activeCategory={activeCategory} />
      ) : null}
      <SectionContainer backgroundColor="white">
        <div className="blog-list-container">
          <div className="blog-list-grid">
            {data.nodes.map((post) => (
              <PostCard key={post.id} post={post} />
            ))}
          </div>
        </div>
      </SectionContainer>
    </>
  );
}
