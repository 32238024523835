import React from 'react';
import vectorSVG from './Vector.svg';
import './hero-info-pane.css';

const HeroInfoPane = ({ children }) => (
  <div className="hero-info-pane">
    <div className="back-button-container">
      <img src={vectorSVG} alt="" />
      <button type="button" onClick={() => window.history.back()}>
        Back
      </button>
    </div>
    <div className="content">{children}</div>
  </div>
);

export default HeroInfoPane;
