import React from 'react';

// import './change-the-world.css';

import HeroPane from './hero-pane';

import poster from '../Innovation.jpg';
import video2 from '../WebsiteAnimationsCompressed/Innovation.mp4';

const Innovation = ({
  onExitedPane,
  shouldDisplay,
  cx,
  cy,
  startCircleRadius,
  endCircleRadius,
}) => (
  <HeroPane
    id="innovation"
    poster={poster}
    video={video2}
    shouldDisplay={shouldDisplay}
    onExitedPane={onExitedPane}
    cx={cx}
    cy={cy}
    startCircleRadius={startCircleRadius}
    endCircleRadius={endCircleRadius}
  >
    <h2>Leveraging Innovation</h2>
    <p>
      We're the ones to call when you want to leverage cutting-edge technology,
      use traditional tech in a new way, or simply do something your industry
      has never seen.
    </p>
    <a href="https://www.crosscomm.com/portfolio/smithsonian-national-museum-of-african-art">
      Explore Case Study
    </a>
  </HeroPane>
);

export default Innovation;
