import React from 'react';

import PostCard from '../Card/PostCard';
import AccessibleSlider from '../AccessibleSlider';

const SLIDER_SETTINGS = {
  dots: true,
  arrows: false,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
  autoplay: true,
};

export default function FeaturedItemsHeroCarousel({ items }) {
  return (
    <div className="featured-items-hero-carousel">
      <AccessibleSlider settings={SLIDER_SETTINGS}>
        {items.map((item) => (
          <PostCard key={item.id} post={item} />
        ))}
      </AccessibleSlider>
    </div>
  );
}
