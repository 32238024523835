import React, { useEffect, useMemo, useRef, useState } from 'react';
import FakeDOMNode from '../utils/fake-dom-node';
import RichText from './RichText';

const getModifiedFormHTML = (originalFormHTMLText, submitButtonClassName) => {
  /**
   * Since the HTML from Get Response is not formatted in a great way
   * to style we parse the HTML and convert it into a more stylable format.
   * Primarily this is to wrap text nodes into <spans>, however we also
   * wrap all of the input fields into a <fieldset> element.
   *
   * Original HTML from GetResponse:
   =================================
  <form
    action="https://app.getresponse.com/add_subscriber.html"
    accept-charset="utf-8"
    method="post"
  >
    name: <input type="text" name="name" /><br />
    email: <input type="text" name="email" /><br />
    <input
      id="webform_consent#zWsQ_0"
      type="checkbox"
      name="webform[consent#zWsQ-ver#wwrq]"
      value="true"
    />
    <label for="webform_consent#zWsQ_0">
      I agree to the processing of my personal data for marketing purposes
    </label>
    <input type="hidden" name="campaign_token" value="rjX5s" />
    <input type="hidden" name="start_day" value="0" />
    <input type="submit" value="Subscribe" />
  </form>

	Modified HTML from getModifiedFormHTML:
	=====================================
  <form
    action="https://app.getresponse.com/add_subscriber.html"
    accept-charset="utf-8"
    method="post"
  >
    <fieldset>
      <span>Name:</span><input type="text" name="name" placeholder="Name" />
      <span>Email:</span><input type="text" name="email" placeholder="Email" />
      <span aria-hidden> </span>
      <label>
        <input
          id="webform_consent#zWsQ_0"
          type="checkbox"
          name="webform[consent#zWsQ-ver#wwrq]"
          value="true"
          required
        />
        <span>
          I agree to the processing of my personal data for marketing purposes
        </span>
      </label>
      <input type="hidden" name="campaign_token" value="rjX5s" />
      <input type="hidden" name="start_day" value="0" />
    </fieldset>
    <div>
      <input type="submit" value="Subscribe" />
    </div>
  </form>
 */

  const container = new FakeDOMNode('div');
  container.innerHTML = originalFormHTMLText.replace(/[^\S ]+/gm, '');

  const formNode = container.find((el) => el.tagName === 'form')[0];
  const fieldset = new FakeDOMNode('fieldset');
  const buttonsContainer = new FakeDOMNode('div');

  buttonsContainer.classList.add('submit-button-container');

  let lastTextNode;
  let lastCheckbox;

  // Run through all child nodes...
  formNode.children.forEach((node) => {
    // (We only care about element and text nodes)
    if (node.nodeType !== 'element' && node.nodeType !== 'text') {
      return;
    }

    switch (node.tagName.toUpperCase()) {
      // When we find a text node wrap it in a span
      case 'TEXT': {
        node.textContent = node.textContent.trim();
        if (node.textContent.length === 0) {
          // If the node is just whitespace we ignore it
          break;
        }

        const span = new FakeDOMNode('span');

        span.appendChild(node);
        fieldset.appendChild(span);
        lastTextNode = node;

        break;
      }

      // When we find an element node we keep it if it's a label, input, select, or textarea,
      // otherwise we toss it
      case 'LABEL': {
        const checkboxContainerEl = new FakeDOMNode('label');
        const spanEl = new FakeDOMNode('span');

        spanEl.textContent = node.textContent;
        lastCheckbox.setAttribute('value', 'true');

        lastCheckbox.remove();
        checkboxContainerEl.appendChild(lastCheckbox);
        checkboxContainerEl.appendChild(spanEl);

        const dummySpanEl = new FakeDOMNode('span');
        dummySpanEl.setAttribute('aria-hidden', 'true');
        dummySpanEl.textContent = ' ';

        fieldset.appendChild(dummySpanEl);
        fieldset.appendChild(checkboxContainerEl);
        break;
      }

      case 'INPUT': {
        // debugger;
        // (Submit buttons get put in their own container outside the fieldset)
        if (node.getAttribute('type').toLowerCase() === 'submit') {
          node.classList.add(submitButtonClassName);
          buttonsContainer.appendChild(node);
          break;
        }

        if (node.getAttribute('type').toLowerCase() === 'checkbox') {
          lastCheckbox = node;
        }

        // falls through
      }

      case 'SELECT':
      case 'TEXTAREA': {
        // (Set the placeholder text if it doesn't exist already)
        if (!node.getAttribute('placeholder') && lastTextNode) {
          const placeholderText = lastTextNode.textContent
            .trim()
            .replace(/:$/, '');

          node.setAttribute(
            'placeholder',
            placeholderText.length > 0
              ? placeholderText[0].toUpperCase() + placeholderText.substr(1)
              : ''
          );
        }

        fieldset.appendChild(node);
        break;
      }

      default:
        break;
    }
  });

  formNode.innerHTML = '';
  formNode.appendChild(fieldset);
  formNode.appendChild(buttonsContainer);

  return formNode.outerHTML;
};

const createInternalThankYouPageHiddenElement = (internalThankYouPageURL) => {
  const el = document.createElement('input');

  el.type = 'hidden';
  el.name = 'thankyou_url';
  el.value = internalThankYouPageURL;

  return el;
};

const composeInternalThankYouPageURL = (internalThankYouPage) => {
  if (!internalThankYouPage) {
    return null;
  }

  const { parentName, slug } = internalThankYouPage;
  const relURL = `${parentName ? `/${parentName.slug}` : ''}/${slug}`;

  return `${window.location.origin}${relURL}`;
};

const replaceThankYouURLField = (ref, internalThankYouPageURL) => {
  if (!internalThankYouPageURL) {
    return;
  }

  // Find any existing thank you hidden inputs and remove them:
  [
    ...ref.current.querySelectorAll('input[type="hidden"][name="thankyou_url"'),
  ].forEach((inputEl) => inputEl.remove());

  // Now add our own:
  ref.current
    .querySelector('form')
    .appendChild(
      createInternalThankYouPageHiddenElement(internalThankYouPageURL)
    );
};

const getFormData = ({ html, buttonStyle }) => {
  let formHTML = html.html;
  let isFormModified = false;

  // Attempt to parse and massage the original form HTML with our modified HTML:
  try {
    formHTML = getModifiedFormHTML(formHTML, buttonStyle);
    isFormModified = true;
  } catch (e) {
    console.error('Unable to parse form HTML. Falling back to original HTML');
    console.error('HTML:', html);
    console.error('Error:', e);
  }

  return {
    formHTML,
    isFormModified,
  };
};

export default function GetResponseHTMLForm({ data }) {
  const selfRef = useRef();

  const { formHTML, isFormModified } = useMemo(() => getFormData(data), [data]);

  // Need to run inside useEffect since we need the browser to grab the origin
  useEffect(() => {
    // Attempt to replace/add our own thank you URL hidden element:
    try {
      // Get the internal thank-you page URL (if one exists):
      const internalThankYouPageURL = composeInternalThankYouPageURL(
        data.internalThankYouPage
      );

      replaceThankYouURLField(selfRef, internalThankYouPageURL);
    } catch (e) {
      console.error('Unable to inject internal thank you page URL!');
      console.error('Error:', e);
    }
  }, [data.internalThankYouPage]);

  return (
    <div
      ref={selfRef}
      className={`get-response-html-form is-form-style-${data.formStyle} is-${
        isFormModified ? 'transformed' : 'not-transformed'
      }`}
    >
      {data.title ? <h2>{data.title}</h2> : null}
      {data.description ? <RichText content={data.description} /> : null}
      {!isFormModified ? <FormParseErrorNotice /> : null}
      <div
        className="parsed-form"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: formHTML }}
      />
    </div>
  );
}

const FormParseErrorNotice = () => (
  <div className="form-parse-error-notice">
    💥 Something went wrong styling our form! We've fallen back on the un-styled
    version for now. It doesn't look how we intended but it will still work.
  </div>
);
