import React from 'react';

import './change-the-world.css';

import HeroPane from './hero-pane';

import poster from '../ChangeTheWorld.jpg';
import video2 from '../WebsiteAnimationsCompressed/ChangeTheWorld.mp4';

const ChangeTheWorld = ({
  onExitedPane,
  shouldDisplay,
  cx,
  cy,
  startCircleRadius,
  endCircleRadius,
}) => (
  <HeroPane
    id="change-the-world"
    poster={poster}
    video={video2}
    shouldDisplay={shouldDisplay}
    onExitedPane={onExitedPane}
    cx={cx}
    cy={cy}
    startCircleRadius={startCircleRadius}
    endCircleRadius={endCircleRadius}
  >
    <h2>Making a Positive Impact</h2>
    <p>
      We believe thoughtful software can foster a healthier and happier society.
      Let's collaborate to improve people's lives — whether it's a small group
      or the entire globe.
    </p>
    <a href="https://www.crosscomm.com/portfolio/well-aware">
      Explore Case Study
    </a>
  </HeroPane>
);

export default ChangeTheWorld;
