import React from 'react';
import ContentWrapper from '../ContentWrapper';
import SectionContainer from '../SectionContainer';
import GetResponseHTMLForm from '../GetResponseHTMLForm';

export default function GetResponseHTMLFormSection({ data }) {
  return (
    <SectionContainer {...data} className="get-response-html-form-section">
      <ContentWrapper>
        <GetResponseHTMLForm data={data} />
      </ContentWrapper>
    </SectionContainer>
  );
}
