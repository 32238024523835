import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ContentWrapper from '../ContentWrapper';
import SectionContainer from '../SectionContainer';

export default function IconList({ data }) {
  return (
    <SectionContainer
      {...data}
      backgroundColor={data.backgroundColor || 'grey'}
    >
      <ContentWrapper>
        <div className="icon-list-container">
          {data.title ? (
            <h2 className="icon-list-title colored-title">{data.title}</h2>
          ) : null}
          <div className="icon-list-grid">
            {data.icons.map((icon) => (
              <div key={icon.id} className="icon-list-grid-item">
                {icon.icon.gatsbyImageData ? (
                  <GatsbyImage
                    alt={icon.title}
                    className={`icon-list-grid-image ${data.size}`}
                    image={icon.icon.gatsbyImageData}
                  />
                ) : (
                  <img
                    src={icon.icon.file.url}
                    alt={icon.title}
                    className={`icon-list-grid-image ${data.size}`}
                  />
                )}
                <h3>{icon.title}</h3>
                <p>{icon.description}</p>
              </div>
            ))}
          </div>
        </div>
      </ContentWrapper>
    </SectionContainer>
  );
}
