import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as color from '../../styles/config/_variables.module.scss';
import ContentWrapper from '../ContentWrapper';
import ContactForm from '../ContactForm';
import Logo from '../../assets/images/logo.png';
import GetResponseHTMLForm from '../GetResponseHTMLForm';

export default function HeroLanding({ hero, pageName }) {
  const getMedia = () => {
    if (hero.mediaImageVideo.file.contentType.includes('video')) {
      return (
        <video
          lazy
          autoPlay
          muted
          loop
          playsInline
          poster=""
          src={hero.mediaImageVideo.file.url}
        />
      );
    }

    if (hero.mediaImageVideo.file.contentType.includes('image')) {
      return (
        <GatsbyImage
          style={{
            gridArea: '1/1',
            minHeight: 800,
          }}
          // This is a presentational image, so the alt should be an empty string
          alt={
            hero.mediaImageVideo.description || hero.mediaImageVideo.title || ''
          }
          image={hero.mediaImageVideo.gatsbyImageData}
        />
      );
    }

    return null;
  };

  return (
    <div className="landing-hero-container">
      {getMedia()}
      <div className="landing-overlay-content">
        <ContentWrapper>
          <header>
            <Link to="/" className="logo-container">
              <img src={Logo} alt="CrossComm" className="logo" />
            </Link>
            <p className="company-description">{hero.companyDescription}</p>
          </header>
          <div className="main-container">
            <div className="left-container">
              <h1 className="landing-hero-title">
                {hero.coloredTitle
                  .map((text) => (
                    <span
                      key={text.id}
                      style={{ color: color[`${text.color}`] }}
                    >
                      {text.string}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ' ', curr])}
              </h1>
              <p className="landing-hero-subtitle">
                {
                  hero.childContentfulHeroLandingSectionDescriptionTextNode
                    .description
                }
              </p>
            </div>
            <div className="form-container">
              <Form data={hero.contactForm} pageName={pageName} />
            </div>
          </div>
        </ContentWrapper>
      </div>
    </div>
  );
}

const Form = ({ data, pageName = null }) => {
  switch (data.sys.contentType.sys.id) {
    case 'getResponseHtmlForm':
      return <GetResponseHTMLForm data={data} />;

    case 'contactForm':
      return (
        <ContactForm
          key={data.id}
          data={{
            ...data,
            title: data.formRightDescription,
          }}
          pageName={pageName}
        />
      );

    default:
      return null;
  }
};
