import './drag-and-drop.css';
import './drag-arrow.css';

import React, { useEffect, useState, useRef } from 'react';

import video from './WebsiteAnimationsCompressed/LandingPage.mp4';
import ChangeTheWorld from './components/change-the-world';
import ProblemSolving from './components/problem-solving';
import Innovation from './components/innovation';
import Draggable from './components/draggable';
import Arrow from './components/arrow';
import { getIsReducedMotionPreferred, shuffle } from './util';

const DRAG_EL_DIAMETER = 150;
const ANIMATION_DURATION_MS = 200;
const MAX_MOUSE_TRAVEL_DISTANCE_TO_ACTIVATE_CLICK_MODE = 5;
const FUDGE_RANGE = 100;

/** **********************************************************

     Here are the various "steps" that the UI can be in:
=============================================================

                    Start
                      ↓
               ┌--------------┐
               | ATTRACT_MODE | // Show the Drag N' Drop arrow
               └--------------┘

         @TODO

                   ┌------┐ // (The "doing nothing" state)
                   | IDLE |←---------------------------------┐
                   └------┘                                  |
                      |                                      |
     ┌----------------+-----------------┐                    |
     |                                  |                    |
 User holds                          User tabs               |
 down mouse                ┌-------→ to a drag               |
   btn on                  |           item                  |
 drag item                 |             |                   |
     ↓                     |             ↓                   |
┌----------┐          ┌----------┐  ┌----------┐             |
| INTERACT | User     | INTERACT |  | INTERACT |             |
|   WITH   | let go   |  WITH    |  |   WITH   |             |
|  MOUSE   |-btn w/o-→|  MOUSE   |  | KEYBOARD |             |
|   DRAG   | moving   |  CLICK   |  |          |             |
└----------┘ cursor*  └----------┘  └----------┘             |
        |                  |              |   User press     ↑
        └-------------+----+--------------+--ESC or clicks--→+
                      |                       background     |
                      |                                      |
        User places drag item in drop zone,                  |
        clicks drop zone with a drag item                    |
       selected or hit Enter or space with                   |
            a drag item selected                             |
                      ↓                                      |
              ┌----------------┐                             |
              | SNAP DRAGGABLE | // Animates drag item       |
              | INTO DROP ZONE | // over the drop zone       |
              └----------------┘                             |
                      |                                      |
             (Animation completes)                           |
                      ↓                                      |
                ┌-----------┐                                |
                | VIEW PANE | // Show one of the videos      |
                └-----------┘                                |
                      |                                      |
        User clicks 'Back' or hits ESC                       |
                      ↓                                      |
               ┌--------------┐                              |
               | EXITING PANE | // Shrink animation          |
               └--------------┘                              |
                      |                                      |
             (Animation completes)                           |
                      ↓                                      |
            ┌-------------------┐                            |
            | RESTORE DRAGGABLE | // Animate drag item back  |
            | POSITION TO IDLE  | // to its prev. position   |
            └-------------------┘                            |
                      |                                      |
             (Animation completes)                           ↑
                      └--------------------------------------┘

* = If the user mouses down then mouses up on a drag item
    without moving the item much we count that as a "click" and
    switch the drag mode to INTERACT_WITH_MOUSE_CLICK - see
    MAX_MOUSE_TRAVEL_DISTANCE_TO_ACTIVATE_CLICK_MODE

************************************************************* */
const steps = {
  ATTRACT_MODE: 'attract-mode',
  IDLE: 'idle',
  INTERACT_WITH_MOUSE_DRAG: 'interact-with-mouse-drag',
  INTERACT_WITH_MOUSE_CLICK: 'interact-with-mouse-click',
  INTERACT_WITH_KEYBOARD: 'interact-with-keyboard',
  SNAP_DRAGGABLE_INTO_DROP_ZONE: 'snap-draggable-item-into-drop-zone',
  VIEW_PANE: 'view-pane',
  EXITING_PANE: 'exiting-pane',
  RESTORE_DRAGGABLE_POSITION_TO_IDLE: 'restore-draggable-position-to-idle',
};

const panes = {
  INNOVATION: 'innovation',
  PROBLEM_SOLVING: 'problem-solving',
  CHANGE_THE_WORLD: 'change-the-world',
};

const getDropZonePoint = (currentDropZoneBBox, currentDragAndDropZoneBBox) => {
  if (!currentDropZoneBBox || !currentDragAndDropZoneBBox) {
    return null;
  }

  return {
    left: currentDropZoneBBox.left - currentDragAndDropZoneBBox.left + 0,
    top: currentDropZoneBBox.top - currentDragAndDropZoneBBox.top + 0,
  };
};

const getDropZoneCenterPoint = (
  currentDropZoneBBox,
  currentDragAndDropZoneBBox
) => {
  const dropZonePt = getDropZonePoint(
    currentDropZoneBBox,
    currentDragAndDropZoneBBox
  );

  if (!dropZonePt) {
    return null;
  }

  const pt = {
    left: dropZonePt.left + currentDropZoneBBox.width / 2,
    top: dropZonePt.top + currentDropZoneBBox.height / 2,
  };

  return pt;
};

export const DragAndDrop = () => {
  const dragAndDropZoneRef = useRef(null);
  const dropZoneRef = useRef(null);

  const [dragState, setDragState] = useState({
    step: steps.ATTRACT_MODE,
    source: null,
  });
  const [dragItemOffsets, setDragItemOffsets] = useState(null);
  const [dragItemsPos, setDragItemsPos] = useState({
    [panes.INNOVATION]: null,
    [panes.PROBLEM_SOLVING]: null,
    [panes.CHANGE_THE_WORLD]: null,
  });
  const [dropZoneBBox, setDropZoneBBox] = useState(null);
  const [isInDropZone, setIsInDropZone] = useState(false);
  const [zIndexOrder, setZIndexOrder] = useState([
    panes.INNOVATION,
    panes.PROBLEM_SOLVING,
    panes.CHANGE_THE_WORLD,
  ]);
  const [arrowPt, setArrowPt] = useState({ top: 0, left: 0, direction: 'up' });
  const [separationDist, setSeparationDist] = useState(null);
  const [dragAndDropZoneBBox, setDragAndDropZoneBBox] = useState(null);
  const [initialDragPositions, setInitialDragPositions] = useState(null);
  const [dragArrowPts, setDragArrowPts] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);

  const update = ({ clientX, clientY }) => {
    const newLeft = Math.min(
      dragAndDropZoneBBox.width - DRAG_EL_DIAMETER,
      Math.max(0, clientX - dragItemOffsets.mouseX + dragItemOffsets.itemX)
    );
    const newTop = Math.min(
      dragAndDropZoneBBox.height - DRAG_EL_DIAMETER,
      Math.max(0, clientY - dragItemOffsets.mouseY + dragItemOffsets.itemY)
    );

    const dropZoneCX =
      dropZoneBBox.x + dropZoneBBox.width / 2 - dragAndDropZoneBBox.left;
    const dropZoneCY =
      dropZoneBBox.y + dropZoneBBox.height / 2 - dragAndDropZoneBBox.top;
    const dragItemCX = newLeft + DRAG_EL_DIAMETER / 2;
    const dragItemCY = newTop + DRAG_EL_DIAMETER / 2;

    // const debug = document.getElementById('debug');
    // debug.style.left = dragItemCX + 'px';
    // debug.style.top = dragItemCY + 'px';
    // const debug2 = document.getElementById('debug2');
    // debug2.style.left = dropZoneCX + 'px';
    // debug2.style.top = dropZoneCY + 'px';

    const dist = Math.sqrt(
      Math.pow(dropZoneCX - dragItemCX, 2) +
        Math.pow(dropZoneCY - dragItemCY, 2)
    );
    const targetDist = dropZoneBBox.width / 2 + DRAG_EL_DIAMETER / 2;
    const currentSeparationDist = dist - targetDist;
    const newLeftPerc = (newLeft / dragAndDropZoneBBox.width) * 100;
    const newTopPerc = (newTop / dragAndDropZoneBBox.height) * 100;

    // const debug = document.getElementById('debug');
    // debug.style.left = newLeftPerc + '%';
    // debug.style.top = newTopPerc + '%';

    setSeparationDist(currentSeparationDist);
    setIsInDropZone(dist <= targetDist);
    setDragItemsPos((prevState) => ({
      ...prevState,
      [dragState.source]: {
        left: newLeftPerc,
        top: newTopPerc,
      },
    }));

    const dropZoneCenterPt = getDropZoneCenterPoint(
      dropZoneBBox,
      dragAndDropZoneBBox
    );

    const xDim = dropZoneCenterPt.left - newLeft - DRAG_EL_DIAMETER / 2;
    const yDim = dropZoneCenterPt.top - newTop - DRAG_EL_DIAMETER / 2;
    const angleInRads = Math.atan2(yDim, xDim);

    setDragArrowPts({
      left:
        ((newLeft + DRAG_EL_DIAMETER / 2) / dragAndDropZoneBBox.width) * 100,
      top: ((newTop + DRAG_EL_DIAMETER / 2) / dragAndDropZoneBBox.height) * 100,
      width:
        Math.sqrt(Math.pow(xDim, 2) + Math.pow(yDim, 2)) -
        dropZoneBBox.width / 2,
      angle: (angleInRads * 180) / Math.PI,
    });
  };

  const onPointerMove = (event) => {
    if (dragState.step !== steps.INTERACT_WITH_MOUSE_DRAG) {
      return;
    }

    update(getEventPositionValues(event));
  };

  const getEventPositionValues = (event) => {
    if (event.touches) {
      return {
        clientX: event.touches[0].clientX,
        clientY: event.touches[0].clientY,
      };
    }

    return {
      clientX: event.clientX,
      clientY: event.clientY,
    };
  };

  const startDrag = (step, source, event) => {
    setDragState({ step, source });

    const { clientX, clientY } = getEventPositionValues(event);

    const bBox = event.target.getBoundingClientRect();
    const currentDragAndDropZoneBBox =
      dragAndDropZoneRef.current.getBoundingClientRect();

    setZIndexOrder((prevState) => [
      ...prevState.filter(
        (prevSource) => prevSource !== event.target.dataset.dragSource
      ),
      event.target.dataset.dragSource,
    ]);
    setDropZoneBBox(dropZoneRef.current.getBoundingClientRect());
    setDragAndDropZoneBBox(currentDragAndDropZoneBBox);
    setDragItemOffsets({
      mouseX: clientX,
      mouseY: clientY,
      itemX: bBox.left - currentDragAndDropZoneBBox.left,
      itemY: bBox.top - currentDragAndDropZoneBBox.top,
    });
    setMousePosition({
      x: clientX,
      y: clientY,
    });
  };

  const viewPane = () => {
    setDragState((prevState) => ({
      ...prevState,
      step: steps.SNAP_DRAGGABLE_INTO_DROP_ZONE,
    }));

    const currentDropZoneBBox = dropZoneRef.current.getBoundingClientRect();
    const currentDragAndDropZoneBBox =
      dragAndDropZoneRef.current.getBoundingClientRect();

    const dropZonePoint = getDropZonePoint(
      currentDropZoneBBox,
      currentDragAndDropZoneBBox
    );

    const leftX =
      dropZonePoint.left +
      (currentDropZoneBBox.width - DRAG_EL_DIAMETER) / 2 -
      2;
    const topY =
      dropZonePoint.top +
      (currentDropZoneBBox.height - DRAG_EL_DIAMETER) / 2 +
      6;

    const debug = document.getElementById('debug');
    debug.style.left = `${(leftX / currentDragAndDropZoneBBox.width) * 100}%`;
    debug.style.top = `${(topY / currentDragAndDropZoneBBox.height) * 100}%`;

    setDragItemsPos((prevState) => ({
      ...prevState,
      [dragState.source]: {
        left: (leftX / currentDragAndDropZoneBBox.width) * 100,
        top: (topY / currentDragAndDropZoneBBox.height) * 100,
      },
    }));

    // Prevent browser from navigating back to another website if clicking browser back button instead of back link
    window.history.pushState({}, '', '/');
  };

  const stopDrag = (event) => {
    if (document.activeElement) {
      document.activeElement.blur();
    }

    if (isInDropZone) {
      viewPane();
      return;
    }

    const { clientX, clientY } = getEventPositionValues(event);

    const distanceMouseTraveled = Math.sqrt(
      Math.pow(clientX - mousePosition.x, 2) +
        Math.pow(clientY - mousePosition.y, 2)
    );
    if (
      distanceMouseTraveled <= MAX_MOUSE_TRAVEL_DISTANCE_TO_ACTIVATE_CLICK_MODE
    ) {
      setDragState((prevState) => ({
        ...prevState,
        step: steps.INTERACT_WITH_MOUSE_CLICK,
      }));
    } else {
      setDragState({ step: steps.IDLE, source: null });
    }
  };

  const onMouseDownDropZone = (event) => {
    event.preventDefault();
    event.stopPropagation();

    viewPane();
  };

  const onExitingPane = () => {
    setDragState((prevState) => ({
      ...prevState,
      step: steps.EXITING_PANE,
    }));
  };

  const onExitedPane = () => {
    setDragItemOffsets(null);
    setIsInDropZone(false);
    setSeparationDist(null);
    setDropZoneBBox(null);
    setDragAndDropZoneBBox(null);
    setDragItemsPos((prevState) => ({
      ...prevState,
      [dragState.source]: initialDragPositions[dragState.source],
    }));
    setDragArrowPts(null);
    // setArrowPt(null);
    setMousePosition(null);
    setDragState({
      step: steps.RESTORE_DRAGGABLE_POSITION_TO_IDLE,
      source: null,
    });
  };

  const dropZoneCenterPoint = getDropZoneCenterPoint(
    dropZoneBBox,
    dragAndDropZoneBBox
  );

  const onHeroMouseDown = () => {
    if (
      dragState.step === steps.INTERACT_WITH_MOUSE_CLICK ||
      dragState.step === steps.INTERACT_WITH_KEYBOARD
    ) {
      setDragState({
        step: steps.IDLE,
        source: null,
      });
    }
  };

  const onFocusDragItem = (event) => {
    event.preventDefault();

    if (dragState.step === steps.VIEW_PANE) {
      return;
    }

    // @TODO
    startDrag(steps.INTERACT_WITH_KEYBOARD, event.target.dataset.dragSource, {
      ...event,
      clientX: 0,
      clientY: 0,
    });
  };

  const onBlurDragItem = (event) => {
    setDragState({
      step: steps.IDLE,
      source: null,
    });
  };

  const onDropZoneKeyPress = (event) => {
    switch (event.key) {
      case ' ':
      case 'Spacebar':
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();

        onMouseDownDropZone(event);
        break;

      default:
        break;
    }
  };

  const onDragItemPointerDown = (event) => {
    startDrag(
      steps.INTERACT_WITH_MOUSE_DRAG,
      event.target.dataset.dragSource,
      event
    );
  };

  useEffect(() => {
    const currentDragAndDropZoneBBox =
      dragAndDropZoneRef.current.getBoundingClientRect();
    const w = currentDragAndDropZoneBBox.width / 4;
    const h = currentDragAndDropZoneBBox.height / 4;
    const parentW = currentDragAndDropZoneBBox.width;
    const parentH = currentDragAndDropZoneBBox.height;
    const r = DRAG_EL_DIAMETER / 2;

    const f = () => Math.floor(Math.random() * FUDGE_RANGE) - FUDGE_RANGE / 2;

    let positions;
    let currentArrowPt;
    if (Math.random() < 0.5) {
      /**
       * ( )
       *      [ ]    ( )
       * ( )
       */

      positions = [
        {
          left: ((w - r + f()) / parentW) * 100,
          top: ((h * 1.5 - r + f()) / parentH) * 100,
        },
        {
          left: ((w * 3 - r + f()) / parentW) * 100,
          top: ((h * 2 - r + f()) / parentH) * 100,
        },
        {
          left: ((w - r + f()) / parentW) * 100,
          top: ((h * 3 - r + f()) / parentH) * 100,
        },
      ];
      currentArrowPt = { ...positions[2], direction: 'up' };
    } else {
      /**
       *             ( )
       * ( )  [ ]
       *             ( )
       */

      positions = [
        {
          left: ((w * 3 - r + f()) / parentW) * 100,
          top: ((h * 1.5 - r + f()) / parentH) * 100,
        },
        {
          left: ((w - r + f()) / parentW) * 100,
          top: ((h * 2 - r + f()) / parentH) * 100,
        },
        {
          left: ((w * 3 - r + f()) / parentW) * 100,
          top: ((h * 3 - r + f()) / parentH) * 100,
        },
      ];

      currentArrowPt = { ...positions[1], direction: 'down' };
    }

    positions = shuffle(positions);

    setInitialDragPositions({
      [panes.INNOVATION]: positions[0],
      [panes.PROBLEM_SOLVING]: positions[1],
      [panes.CHANGE_THE_WORLD]: positions[2],
    });

    setDragItemsPos({
      [panes.INNOVATION]: positions[0],
      [panes.PROBLEM_SOLVING]: positions[1],
      [panes.CHANGE_THE_WORLD]: positions[2],
    });
    setArrowPt(currentArrowPt);

    // Trigger animation on browser "back" navigation
    window.addEventListener('popstate', onExitingPane);

    return () => {
      window.removeEventListener('popstate', onExitingPane);
    };
  }, []);

  useEffect(() => {
    const onDocumentKeyUp = (event) => {
      if (event.key !== 'Escape') {
        return;
      }

      switch (dragState.step) {
        case steps.VIEW_PANE:
          onExitingPane();
          break;

        default:
          setDragState({
            source: null,
            step: steps.IDLE,
          });
          break;
      }
    };

    document.addEventListener('keyup', onDocumentKeyUp);

    return () => {
      document.removeEventListener('keyup', onDocumentKeyUp);
    };
  }, [dragState.step, onExitingPane]);

  useEffect(() => {
    if (mousePosition) {
      update({
        clientX: mousePosition.x,
        clientY: mousePosition.y,
      });
    }
  }, [mousePosition]);

  useEffect(() => {
    const isReducedMotionPreferred = getIsReducedMotionPreferred();

    switch (dragState.step) {
      case steps.INTERACT_WITH_MOUSE_CLICK:
        dropZoneRef.current.focus(); // @TODO
        break;

      case steps.SNAP_DRAGGABLE_INTO_DROP_ZONE:
        setTimeout(
          () => {
            setDragState((prevState) => ({
              ...prevState,
              step: steps.VIEW_PANE,
            }));
          },
          isReducedMotionPreferred ? 0 : ANIMATION_DURATION_MS
        );
        break;

      case steps.RESTORE_DRAGGABLE_POSITION_TO_IDLE:
        setTimeout(
          () => {
            setDragState((prevState) => ({ ...prevState, step: steps.IDLE }));
          },
          isReducedMotionPreferred ? 0 : ANIMATION_DURATION_MS
        );
        break;

      default:
        break;
    }
  }, [dragState]);

  const isInteracting =
    dragState.step === steps.INTERACT_WITH_KEYBOARD ||
    dragState.step === steps.INTERACT_WITH_MOUSE_CLICK ||
    dragState.step === steps.INTERACT_WITH_MOUSE_DRAG;

  let glowAmt = 0;
  if (separationDist < 400) {
    glowAmt = Math.min(400, 400 - separationDist) / 400;
  }

  const isDragItemsFocusable = dragState.step === steps.IDLE || isInteracting;

  return (
    <div
      className={`drag-and-drop is-step-${dragState.step}${
        isInteracting ? ' is-interacting' : ''
      }${isInDropZone ? ' is-in-drop-zone' : ''}`}
    >
      <div className="hero-container" id="hero-container">
        <div
          className="hero"
          onMouseDown={onHeroMouseDown}
          style={{ transform: `scale(${1})` }}
        >
          <div id="bg-video-container">
            <canvas id="bg-video-canvas" />
            <video
              aria-hidden
              id="bg-video"
              src={video}
              autoPlay
              muted
              loop
              playsInline
            />
          </div>
          <header>
            <h1>
              Quality Software Development <b>For Your Big Idea</b>
            </h1>
            <h2>Web, Mobile, and Immersive App Development</h2>
          </header>
          <div ref={dragAndDropZoneRef} id="dnd-zone">
            <div
              aria-hidden
              className="drop-zone"
              id="drop-zone"
              ref={dropZoneRef}
              onMouseDown={onMouseDownDropZone}
              onKeyPress={onDropZoneKeyPress}
              tabIndex={
                dragState.step === steps.INTERACT_WITH_MOUSE_CLICK ? 0 : null
              }
            >
              <span>WHY US?</span>
            </div>

            {dragArrowPts ? (
              <div
                className="drag-arrow"
                style={{
                  left: `${dragArrowPts.left}%`,
                  top: `${dragArrowPts.top}%`,
                  width: `${dragArrowPts.width}px`,
                  transform: `rotate(${dragArrowPts.angle}deg)`,
                  opacity: isInteracting ? 0.2 + glowAmt * 0.8 : 0,
                }}
              >
                <svg>
                  <line x1="0" y1="4" x2="100%" y2="4" />
                </svg>
              </div>
            ) : null}

            <Draggable
              name={panes.INNOVATION}
              isFocusable={isDragItemsFocusable}
              onFocus={onFocusDragItem}
              onBlur={onBlurDragItem}
              onPointerDown={onDragItemPointerDown}
              onPointerMove={onPointerMove}
              onPointerUp={stopDrag}
              onKeySubmit={viewPane}
              isDragging={dragState.source === panes.INNOVATION}
              zIndex={zIndexOrder.findIndex(
                (source) => source === panes.INNOVATION
              )}
              {...dragItemsPos[panes.INNOVATION]}
            >
              Press enter to learn more about CrossComm's mission on innovation.
            </Draggable>
            <Draggable
              name={panes.PROBLEM_SOLVING}
              isFocusable={isDragItemsFocusable}
              onFocus={onFocusDragItem}
              onBlur={onBlurDragItem}
              onPointerDown={onDragItemPointerDown}
              onPointerMove={onPointerMove}
              onPointerUp={stopDrag}
              onKeySubmit={viewPane}
              isDragging={dragState.source === panes.PROBLEM_SOLVING}
              zIndex={zIndexOrder.findIndex(
                (source) => source === panes.PROBLEM_SOLVING
              )}
              {...dragItemsPos[panes.PROBLEM_SOLVING]}
            >
              Press enter to learn more about CrossComm's mission on problem
              solving.
            </Draggable>
            <Draggable
              name={panes.CHANGE_THE_WORLD}
              isFocusable={isDragItemsFocusable}
              onFocus={onFocusDragItem}
              onBlur={onBlurDragItem}
              onPointerDown={onDragItemPointerDown}
              onPointerMove={onPointerMove}
              onPointerUp={stopDrag}
              onKeySubmit={viewPane}
              isDragging={dragState.source === panes.CHANGE_THE_WORLD}
              zIndex={zIndexOrder.findIndex(
                (source) => source === panes.CHANGE_THE_WORLD
              )}
              {...dragItemsPos[panes.CHANGE_THE_WORLD]}
            >
              Press enter to learn more about CrossComm's mission on changing
              the world.
            </Draggable>
            <Arrow
              startPt={{ left: arrowPt.left, top: arrowPt.top }}
              direction={arrowPt.direction}
            />

            <div className="debug" id="debug" />
            <div className="debug" id="debug2" />
          </div>

          <Innovation
            onExitedPane={onExitedPane}
            shouldDisplay={
              dragState.source === panes.INNOVATION &&
              dragState.step === steps.VIEW_PANE
            }
            cx={dropZoneCenterPoint?.left}
            cy={dropZoneCenterPoint?.top}
            startCircleRadius={DRAG_EL_DIAMETER / 2}
            endCircleRadius={dragAndDropZoneBBox?.width}
          />
          <ProblemSolving
            onExitedPane={onExitedPane}
            shouldDisplay={
              dragState.source === panes.PROBLEM_SOLVING &&
              dragState.step === steps.VIEW_PANE
            }
            cx={dropZoneCenterPoint?.left}
            cy={dropZoneCenterPoint?.top}
            startCircleRadius={DRAG_EL_DIAMETER / 2}
            endCircleRadius={dragAndDropZoneBBox?.width}
          />
          <ChangeTheWorld
            onExitedPane={onExitedPane}
            shouldDisplay={
              dragState.source === panes.CHANGE_THE_WORLD &&
              dragState.step === steps.VIEW_PANE
            }
            cx={dropZoneCenterPoint?.left}
            cy={dropZoneCenterPoint?.top}
            startCircleRadius={DRAG_EL_DIAMETER / 2}
            endCircleRadius={dragAndDropZoneBBox?.width}
          />
        </div>
      </div>
    </div>
  );
};

/*
Estimate all tasks
1. CSS updates
2.
 */
