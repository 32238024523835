import React from 'react';
import Slider from 'react-slick';

import Button from '../Button/Button';
import Card from '../Card/Card';
import CustomLink from '../../utils/CustomLink';
import SectionContainer from '../SectionContainer';
import AccessibleSlider from '../AccessibleSlider';

const stopNextButtonAnimation = () => {
  const el = document.getElementsByClassName('slick-next')[0];

  el.classList.remove('is-playing');
};

const resetNextButtonAnimation = () => {
  const el = document.getElementsByClassName('slick-next')[0];

  el.classList.remove('is-playing');

  // Trigger reflow, which will redraw the element:
  // eslint-disable-next-line no-void
  void el.offsetWidth;

  el.classList.add('is-playing');
};

export default function FeaturedPostCarousel({ data }) {
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplaySpeed: 5000,
    variableWidth: true,
    beforeChange: resetNextButtonAnimation,
  };

  return (
    <SectionContainer className="featured-post-carousel" backgroundColor="blue">
      <div className="featured-post-carousel-title-button">
        <h2 className="colored-title">{data.title}</h2>
        <CustomLink to={data.button.url}>
          <Button buttonStyle={data.button.buttonStyle}>
            {data.button.text}
          </Button>
        </CustomLink>
      </div>
      <div
        className="slider-container"
        onMouseOver={stopNextButtonAnimation}
        onFocus={stopNextButtonAnimation}
        onMouseOut={resetNextButtonAnimation}
        onBlur={resetNextButtonAnimation}
      >
        <AccessibleSlider settings={settings}>
          {data.featuredPosts.map((post) => (
            <div
              key={post.id}
              className="featured-post-carousel-card-container"
            >
              <Card post={post} />
            </div>
          ))}
        </AccessibleSlider>
        {/* <Slider {...settings}>
          {data.featuredPosts.map((post) => (
            <div
              key={post.id}
              className="featured-post-carousel-card-container"
            >
              <Card post={post} />
            </div>
          ))}
        </Slider> */}
      </div>
    </SectionContainer>
  );
}
