import React from 'react';

import ContentWrapper from '../ContentWrapper';
import SectionContainer from '../SectionContainer';
import ContactForm from '../ContactForm';
import SocialIcons from '../SocialIcons';

export default function ContactFormSection({ data, pageName }) {
  return (
    <SectionContainer
      {...data}
      className="contact-form-section"
      backgroundColor="white"
    >
      <ContentWrapper>
        <ContactForm data={data} pageName={pageName} />
        <div className="contact-form-right-content">
          <h2>{data.formRightTitle}</h2>
          <p>{data.formRightDescription}</p>
          <a href={`mailto:${data.email}`}>{data.email}</a>
          <a href={`tel:${data.telephoneNumber}`}>{data.telephoneNumber}</a>
          <p className="social-text">{data.socialText}</p>
          <SocialIcons />
        </div>
      </ContentWrapper>
    </SectionContainer>
  );
}
