import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const getURL = (post) => {
  switch (post.sys.contentType.sys.id) {
    case 'portfolioPiece':
      return `/portfolio/${post.slug}`;

    case 'blogEntry':
      return `/resources/blog/${post.slug}`;

    default:
      return '/';
  }
};

export default function Card({ customStyle, post }) {
  return (
    <div className={`card ${customStyle || ''}`}>
      <Link to={getURL(post)}>
        <div className="image-wrapper">
          <GatsbyImage
            alt={
              post.cardImage?.description ||
              post.cardImage?.title ||
              post.heroImage?.description ||
              post.heroImage?.title ||
              ''
            }
            image={
              post.cardImage?.gatsbyImageData || post.heroImage?.gatsbyImageData
            }
            style={{
              height: 226,
            }}
          />
        </div>
        <button type="button" className="card-button">
          {post.title}
        </button>
      </Link>
    </div>
  );
}
