import React from 'react';

import './arrow.css';

import arrowSVG from './arrow.svg';

const Arrow = ({ startPt, direction }) => {
	if (!startPt) {
		return null;
	}

	return (
		<div
			className={`arrow is-direction-${direction}`}
			style={{ left: `${startPt.left}%`, top: `${startPt.top}%` }}
		>
			<img src={arrowSVG} />
			<span>Drag n' drop</span>
		</div>
	);
};

export default Arrow;
