import React from 'react';

import Card from '../Card/Card';
import SectionContainer from '../SectionContainer';
import ContentWrapper from '../ContentWrapper';

export default function FeaturedPostGrid({ data }) {
  return (
    <SectionContainer
      backgroundColor={data.backgroundColor || 'blue'}
      className="featured-post-grid"
    >
      <div className="featured-post-grid-container">
        <h2 className="colored-title">{data.title}</h2>
        <div className="featured-post-grid-content-grid">
          {data.featuredPosts.map((post) => (
            <Card key={post.id} post={post} />
          ))}
        </div>
      </div>
    </SectionContainer>
  );
}
