import React from 'react';

import './draggable.css';

const Draggable = ({
  name,
  onFocus,
  onBlur,
  onPointerDown,
  onMouseOver,
  onPointerMove,
  onPointerUp,
  onKeySubmit,
  isDragging,
  left,
  top,
  zIndex,
  children,
  isFocusable,
}) => {
  // if (typeof left === 'undefined' || typeof top === 'undefined') {
  // 	return;
  // }

  const onKeyPress = (event) => {
    switch (event.key) {
      case ' ':
      case 'Spacebar':
      case 'Enter':
        event.preventDefault();
        onKeySubmit();
        break;

      default:
        break;
    }
  };

  return (
    <div
      tabIndex={isFocusable ? 0 : null}
      data-drag-source={name}
      className={`draggable drag-item-${name} ${
        isDragging ? 'is-drag-item' : ''
      }`}
      onMouseOver={onMouseOver}
      // onMouseDown={(event) => {
      // 	event.preventDefault();
      // 	event.stopPropagation();

      // 	onMouseDown(event);
      // }}
      onPointerMove={onPointerMove}
      onPointerDown={(event) => {
        event.preventDefault();
        event.stopPropagation();

        event.target.setPointerCapture(event.pointerId);

        onPointerDown(event);
      }}
      onPointerUp={(event) => {
        event.target.releasePointerCapture(event.pointerId);
        onPointerUp(event);
      }}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{
        left: `${left}%`,
        top: `${top}%`,
        zIndex,
      }}
    >
      <span>{children}</span>
    </div>
  );
};

export default Draggable;
