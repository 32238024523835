import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeroLinkIcon = ({ gatsbyImageData, file }) => {
  if (file) {
    return <img aria-hidden alt="" src={file.url} />;
  }
  return <GatsbyImage aria-hidden alt="" image={gatsbyImageData} />;
};

export default function HeroLink({
  topLine,
  bottomLine,
  url,
  openInNewTab,
  icon,
}) {
  return (
    <a
      href={url}
      className={`hero-link${
        icon ? ' is-showing-icon' : ' is-not-showing-icon'
      }`}
      target={openInNewTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      <span className="text">
        <span className="top-line">{topLine}</span>
        {bottomLine ? <span className="bottom-line">{bottomLine}</span> : null}
      </span>
      {icon ? <HeroLinkIcon {...icon} /> : null}
    </a>
  );
}
