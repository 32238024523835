import React, { useEffect, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';

import './lazy-load-video.css';

const LazyLoadVideo = ({ shouldPlayVideo, video, poster }) => {
  const [isVideoReadyToPlay, setIsVideoReadyToPlay] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);

  const videoRef = useRef();

  useEffect(() => {
    // Safari does not emit 'canplay' events consistently for video, we are forcing isVideoReadyToPlay to be true to allow animation to play on Safari browsers
    if (isSafari) {
      setIsVideoReadyToPlay(true);
    }
  }, []);

  const onCanPlayThrough = (event) => {
    setIsVideoReadyToPlay(true);
  };

  useEffect(() => {
    if (shouldPlayVideo && isVideoReadyToPlay) {
      setIsPlayingVideo(true);
    } else {
      setIsPlayingVideo(false);
    }
  }, [shouldPlayVideo, isVideoReadyToPlay]);

  useEffect(() => {
    if (isPlayingVideo) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [isPlayingVideo]);

  return (
    <div
      aria-hidden
      className={`lazy-load-video${isPlayingVideo ? ' is-playing-video' : ''}`}
    >
      <div className="lazy-load-video-container">
        <div className="flex-wrapper">
          <img src={poster} alt="animation background" />
        </div>
        <div className="flex-wrapper">
          <video
            ref={videoRef}
            src={video}
            muted
            loop
            playsInline
            onCanPlayThrough={onCanPlayThrough}
          />
        </div>
      </div>
    </div>
  );
};

export default LazyLoadVideo;
