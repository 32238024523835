import React, { useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import Icon from '../Icon';
import * as color from '../../styles/config/_variables.module.scss';
import CustomLink from '../../utils/CustomLink';
import CircleBackground from './CircleBackground';
import ContentWrapper from '../ContentWrapper';
import { DragAndDrop } from './drag-and-drop/drag-and-drop';
import HeroVersion from '../../utils/version';

export default function HeroHome({ hero }) {
  const blog = useStaticQuery(graphql`
    query {
      latestNews: allContentfulBlogEntry(
        limit: 1
        sort: { order: DESC, fields: publishedDate }
      ) {
        edges {
          node {
            id
            blogTitle
            slug
          }
        }
      }
    }
  `);

  const GetMedia = () => {
    if (hero.mediaImageVideo.file.contentType.includes('video')) {
      return (
        <video
          lazy
          autoPlay
          muted
          loop
          playsInline
          poster=""
          src={hero.mediaImageVideo.file.url}
        />
      );
    }

    if (hero.mediaImageVideo.file.contentType.includes('image')) {
      return (
        <GatsbyImage
          style={{
            gridArea: '1/1',
            maxHeight: 660,
            minHeight: 600,
          }}
          // This is a presentational image, so the alt should be an empty string
          alt={
            hero.mediaImageVideo.description || hero.mediaImageVideo.title || ''
          }
          image={hero.mediaImageVideo.gatsbyImageData}
        />
      );
    }

    return null;
  };

  const [heroVersion, setHeroVersion] = useState('');
  useEffect(() => {
    setHeroVersion(HeroVersion());
  }, []);

  const DefaultHero = () => (
    <div className='static-hero'>
      <GetMedia />
      <div className="home-overlay-content">
        {/* Any content here will be centered in the component */}
        <ContentWrapper>
          <h1 className="home-hero-title">
            {hero.coloredTitle
              .map((text) => (
                <span key={text.id} style={{ color: color[`${text.color}`] }}>
                  {text.string}
                </span>
              ))
              .reduce((prev, curr) => [prev, ' ', curr])}
          </h1>
          <h2 className="home-hero-subtitle">{hero.subTitle}</h2>
          <div className="hero-button-container">
            {hero.buttons.map((button) => (
              <div key={button.id} className="hero-button-margin">
                <CustomLink key={button.id} to={button.url}>
                  <Button buttonStyle={button.buttonStyle}>
                    {button.text}
                  </Button>
                </CustomLink>
              </div>
            ))}
          </div>
        </ContentWrapper>
      </div>
      <div className="lower-bar-container">
        <CircleBackground />
        <div className="latest-news-container">
          <div className="latest-news-wrapper">
            <div>
              <h3>Latest News</h3>
              <Link
                to={`/resources/blog/${blog.latestNews.edges[0].node.slug}/`}
              >
                {blog.latestNews.edges[0].node.blogTitle}
              </Link>
            </div>
          </div>
          <div className="icon-link">
            <Link
              to={`/resources/blog/${blog.latestNews.edges[0].node.slug}/`}
              aria-label={blog.latestNews.edges[0].node.blogTitle}
            >
              <Icon icon="long-arrow-alt-right" size="2x" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="home-hero-container">
      <DefaultHero />
      <DragAndDrop />
    </div>
  );
}
