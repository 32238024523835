import React, { useRef, useEffect } from 'react';
import CustomLink from '../../utils/CustomLink';
import Button from '../Button/Button';
import Icon from '../Icon';

export default function JobPostingModal({ handleCancel, posting }) {
  const selfRef = useRef(null);

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  return (
    <>
      <div
        className="modal job-posting-modal"
        role="dialog"
        aria-live="assertive"
        tabIndex="0"
        ref={selfRef}
      >
        <div className="modal-container">
          <div className="modal-content">
            <button
              type="button"
              className="modal-close-button"
              aria-label="Close dialog"
              onClick={() => handleCancel()}
            >
              <Icon icon="times" size="lg" className="icon-color" />
            </button>
            {/* Header */}
            <div className="modal-header-container">
              <div className="modal-title-container">
                <h3>{posting.title}</h3>
                <p>{posting.type}</p>
              </div>
              <p>
                <Icon icon="map-marker-alt" size="lg" className="icon-color" />{' '}
                {posting.location}
              </p>
            </div>

            {/* Body */}
            <div className="modal-body-container">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: posting.fullListing.childMarkdownRemark.html,
                }}
              />
            </div>

            {/* Footer */}
            <div className="modal-footer">
              <CustomLink to={posting.link}>
                <Button buttonStyle="tertiary">Apply</Button>
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-overlay" />
    </>
  );
}
