import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CircleBackground from './CircleBackground';
import HeroLink from './HeroLink';

export default function HeroGeneric({ data }) {
  const getMedia = () => {
    if (data.mediaImageVideo.file.contentType.includes('video')) {
      return (
        <video
          lazy
          autoPlay
          muted
          loop
          playsInline
          poster=""
          src={data.mediaImageVideo.file.url}
        />
      );
    }

    if (data.mediaImageVideo.file.contentType.includes('image')) {
      return (
        <GatsbyImage
          style={{
            maxHeight: 405,
            minHeight: 334,
          }}
          alt={
            data.mediaImageVideo.description || data.mediaImageVideo.title || ''
          }
          image={data.mediaImageVideo.gatsbyImageData}
        />
      );
    }

    return null;
  };

  return (
    <div className="hero-container">
      {getMedia()}
      <div className="overlay-content">
        {/* Any content here will be centered in the component */}
        <div>
          <h1 className="hero-title">{data.title}</h1>
          {data.subtitle ? (
            <h2 className="hero-subtitle">{data.subtitle}</h2>
          ) : null}
        </div>
      </div>
      <div className="lower-bar-container">
        <CircleBackground />
        {data.heroLink ? <HeroLink {...data.heroLink} /> : null}
      </div>
    </div>
  );
}
